<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on"> Downloads</v-btn>
    </template>
    <v-list>
      <template v-for="el in queues">
        <queued-download-button
          tag="v-list-tile"
          :queue-id="el.id"
          :key="el.id"
        >
          <v-icon class="mx-2">cloud_download</v-icon>
          {{ el.type | nameForReport }}
        </queued-download-button>
      </template>
      <v-list-tile v-if="!queueHasAllRequiredTypes">
        Bitte warten...
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import bookingsApi from '@/api/bookingsApi'
import { isEqual } from 'lodash'
import QueuedDownloadButton from '@/components/exports/QueuedDownloadButton.vue'

const expectedReportsPerSessionType = {
  bookings: [
    'bookings_export',
    'booking_result_xml_pdf_ok_export',
    'booking_combined_pdf_export',
  ],
  prepaymentBookings: [
    'bi_export',
    'booking_result_xml_pdf_ok_export',
    'booking_combined_pdf_export',
  ],
}
export default {
  name: 'BookingsDownloadReports',
  components: { QueuedDownloadButton },
  props: {
    session: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      getQueuesIntervalId: null,
      queues: [],
    }
  },
  computed: {
    queueHasAllRequiredTypes() {
      const availableTypes = this.queues.map((job) => job.type).sort()
      const requiredTypes =
        expectedReportsPerSessionType[this.session.type].sort()
      return isEqual(availableTypes, requiredTypes)
    },
  },

  methods: {
    startCheckingQueues() {
      this.getQueuesIntervalId = setInterval(this.loadQueues, 2000)
    },
    stopCheckingQueues() {
      clearInterval(this.getQueuesIntervalId)
    },

    async loadQueues() {
      const queueData = await bookingsApi.getQueues(this.session.id)
      if (!queueData?.export_queues.length) {
        // stop when there is nothing to fetch
        return
      }

      const requiredJobTypes = expectedReportsPerSessionType[this.session.type]
      this.queues = queueData.export_queues.filter((job) => {
        return requiredJobTypes.includes(job.type)
      })
    },
  },

  filters: {
    nameForReport(type) {
      return {
        bookings_export: 'BI Mappe',
        bi_export: 'BI Mappe',
        booking_result_xml_pdf_ok_export: 'XML Download',
        booking_combined_pdf_export: 'Sammelpdf',
      }[type]
    },
  },

  beforeDestroy() {
    this.stopCheckingQueues()
  },

  watch: {
    'session.active': {
      immediate: true,
      handler(isActive) {
        if (isActive === false) {
          this.startCheckingQueues()
        }
      },
    },
  },
}
</script>

<style scoped></style>
