<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="link-comment"> Lesen </span>
      </slot>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
      </v-card-title>

      <v-card-text class="comment">
        <v-textarea :value="readableText || 'Keine Informationen'" disabled />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TextInfoModal',
  props: {
    comment: {
      default: '',
      type: String,
    },
    title: {
      default: 'Angehängt Kommentar',
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    readableText() {
      try {
        const parsed = JSON.parse(this.comment)
        return JSON.stringify(parsed, null, 3)
      } catch {
        return this.comment
      }
    },
  },
}
</script>

<style scoped>
.link-comment {
  cursor: pointer;
}
.comment {
  word-break: break-word;
  white-space: pre;
  overflow: auto;
}
</style>
