<template>
  <div>
    <template v-if="queue">
      <component :is="tag" :disabled="!queue.finished" @click="startDownload">
        <template v-if="queue.finished">
          <slot />
          <span class="grow pl-3"></span>
          <v-icon v-if="queue.error" color="warning" dark>warning</v-icon>
        </template>
        <template v-else> Erstelle.. {{ queue.progress }}% </template>
      </component>
    </template>
  </div>
</template>

<script>
import queueApi from '@/api/exportQueuesApi'

export default {
  name: 'QueuedDownloadButton',
  props: {
    queueId: {
      type: Number,
      required: true,
    },
    tag: {
      default: 'v-btn',
      type: String,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      queue: null,
      progressUpdater: null,
    }
  },
  computed: {},
  methods: {
    startDownload() {
      queueApi.download(this.queue.id, this.queue.fileName)
    },
    async loadData() {
      this.queue = await queueApi.getEntry(this.queueId)
    },
    startWatching() {
      this.progressUpdater = setInterval(async () => {
        await this.loadData()
        if (this.queue.finished) {
          this.stopWatching()
        }
      }, 2000)
    },
    stopWatching() {
      clearInterval(this.progressUpdater)
    },
  },
  async created() {
    await this.loadData()
    if (this.queue.finished === false) {
      this.startWatching()
    }
  },
  beforeDestroy() {
    this.stopWatching()
  },
}
</script>

<style scoped></style>
