<template>
  <v-list two-line>
    <template v-if="sessionData.active">
      <v-list-tile>
        <v-list-tile-action>
          <v-icon>lock_open</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>Erstellt am</v-list-tile-sub-title>
          <v-list-tile-title
            >{{ sessionData.created_at | formatDate }}
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <template v-else>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>Laufzeit</v-list-tile-sub-title>
          <v-list-tile-title
            >{{ sessionData.created_at | formatDate }}
            -
            {{ sessionData.closed_at | formatDate }}
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="warning">lock</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>Geschlossen von </v-list-tile-sub-title>
          <v-list-tile-title v-if="sessionData.closed_by"
            >{{ sessionData.closed_by.username }}
          </v-list-tile-title>
          <v-list-tile-title v-else>System</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile v-if="sessionData.approved_by">
        <v-list-tile-action>
          <v-icon color="success">done_all</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-sub-title>Buchungsdatum</v-list-tile-sub-title>
          <v-list-tile-title v-if="sessionData.approved_by"
            >{{ sessionData.approved_at | formatDate }} ({{
              sessionData.approved_by.username
            }})
          </v-list-tile-title>
          <v-list-tile-title v-else>System</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-list>
</template>
<script>
import formatDate from 'date-fns/format'

export default {
  name: 'BookingsStatus',
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
  },

  filters: {
    formatDate(date, dateOnly = false) {
      const parts = ['DD.MM.YYYY']

      if (dateOnly) {
        parts.push('(HH:mm [Uhr])')
      }
      return formatDate(new Date(date), parts.join(' '))
    },
  },
}
</script>

<style scoped></style>
