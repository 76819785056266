<template>
  <v-data-table
    v-bind="{ headers, items }"
    v-model="selectedBookings"
    :hide-actions="paginationObj.totalItems === 0"
    :pagination.sync="paginationObj"
    :rows-per-page-items="[10]"
    :total-items="paginationObj.totalItems"
  >
    <template #items="props">
      <tr :class="{ isCanceledLine: props.item.counter_booking_id !== null }">
        <td v-if="selectBookingsMode">
          <v-checkbox
            v-model="props.selected"
            :disabled="
              !!props.item.counter_booking_id ||
              !!props.item.original_booking_id
            "
            primary
            hide-details
          ></v-checkbox>
        </td>
        <td :class="{ isCanceledText: props.item.counter_booking_id !== null }">
          {{ props.item.payment_line | readablePaymentLineType }}
          ({{ props.item | readableBookingType }})
        </td>
        <td :class="{ isCanceledText: props.item.counter_booking_id !== null }">
          {{ props.item.payment_line.payment_data.year }}
        </td>
        <td :class="{ isCanceledText: props.item.counter_booking_id !== null }">
          <a
            v-if="allowedActions.includes('bookings:change')"
            @click="$emit('change:booking', props.item)"
            >{{ props.item.amount | formatAmountToCurrency }}</a
          >
          <span v-else>{{ props.item.amount | formatAmountToCurrency }}</span>
        </td>
        <td>
          {{ props.item.master_data.municipality }}
        </td>
        <td>
          <template v-if="props.item.user">
            {{ props.item.user.username }}
          </template>
          <template v-else> System</template>
        </td>
        <td>
          <a
            @click="$emit('openPdf', props.item.document)"
            v-if="props.item.document"
          >
            {{ props.item.document.pdf | extractNameFromPdf }}
          </a>
          <span v-else>Kein Dokument vorhanden</span>
        </td>
        <template v-if="isIdoc">
          <td>
            <v-tooltip
              left
              v-if="props.item.idoc_attachment_status === 'success'"
            >
              <template #activator="{ on }">
                <v-icon v-on="on" color="success">done_all</v-icon>
              </template>
              IDoc und PDF exportiert
            </v-tooltip>
            <v-tooltip left v-else-if="props.item.idoc_status === 'accepted'">
              <template #activator="{ on }">
                <v-icon v-on="on">schedule</v-icon>
              </template>
              IDoc abgeschickt
            </v-tooltip>
            <TextInfoModal
              v-else-if="['error', 'rejected'].includes(props.item.idoc_status)"
              :comment="props.item.idoc_message"
              title="Fehlermeldung"
            >
              <template #activator="{ on }">
                <v-icon v-on="on" color="error">close</v-icon>
              </template>
            </TextInfoModal>
          </td>
          <td>{{ props.item.idoc_id }}</td>
        </template>
        <td v-else>
          <v-tooltip left v-if="props.item.sap_export_status === 'booked'">
            <template #activator="{ on }">
              <v-icon v-on="on" color="success">check</v-icon>
            </template>
            Erfolgreich exportiert
          </v-tooltip>
          <v-tooltip left v-else-if="props.item.sap_export_status === 'open'">
            <template #activator="{ on }">
              <v-icon v-on="on">schedule</v-icon>
            </template>
            Warte auf Export
          </v-tooltip>
          <v-tooltip left v-else-if="props.item.sap_export_status === 'error'">
            <template #activator="{ on }">
              <v-icon v-on="on" color="error">close</v-icon>
            </template>
            Fehler beim Export
          </v-tooltip>
        </td>
        <td class="text-xs-right">
          {{ props.item.created_at }}
        </td>
      </tr>
    </template>
    <template #pageText="props">
      Einträge {{ props.pageStart }} bis {{ props.pageStop }} von insgesamt
      {{ props.itemsLength }}
    </template>
  </v-data-table>
</template>
<script>
import formatDate from 'date-fns/format'
import {
  readablePaymentLineType,
  readableBookingType,
} from '../../utils/typeToTextHelpers'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import { extractNameFromPdf } from '@/utils/pdfUtils'
import TextInfoModal from '@/components/modals/TextinfoModal.vue'

export default {
  name: 'BookingsTable',
  components: { TextInfoModal },
  props: {
    items: {
      required: true,
      type: Array,
    },
    pagination: {
      required: true,
      type: Object,
    },
    allowedActions: {
      required: true,
      type: Array,
    },
    selectBookingsMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedBookings: [],
    }
  },
  computed: {
    headers() {
      const result = [
        { text: 'Typ', sortable: false },
        { text: 'Jahr', sortable: false },
        { text: 'Betrag', sortable: false },
        { text: 'Kommune', sortable: false },
        { text: 'Benutzer', sortable: false },
        { text: 'Dokument', sortable: false },
      ]

      if (this.selectBookingsMode) {
        result.unshift({ text: '', sortable: false })
      }

      if (this.isIdoc) {
        result.push({ text: 'IDoc Status', sortable: false })
        result.push({ text: 'SAP Belegnummer', sortable: false })
      } else {
        result.push({ text: 'SAP (Inubit)', sortable: false })
      }
      result.push({ text: 'Datum', align: 'right', sortable: false })
      return result
    },
    isIdoc() {
      return this.items?.some((entry) => {
        return entry.external_booking_method === 'idoc'
      })
    },
    paginationObj: {
      get() {
        return this.pagination
      },
      set(value) {
        return this.$emit('update:pagination', value)
      },
    },
  },
  watch: {
    selectBookingsMode() {
      this.selectedBookings = []
    },

    selectedBookings(value) {
      this.$emit('select:booking', value)
    },
  },

  filters: {
    readablePaymentLineType,
    readableBookingType,
    formatDate(date, dateOnly = false) {
      const parts = ['DD.MM.YYYY']

      if (dateOnly) {
        parts.push('(HH:mm [Uhr])')
      }
      return formatDate(new Date(date), parts.join(' '))
    },
    bookingTypeToText(type) {},
    formatAmountToCurrency,
    extractNameFromPdf,
  },
}
</script>

<style scoped>
.isCanceledLine {
  opacity: 0.4;
}
.isCanceledText {
  text-decoration: line-through;
}
</style>
