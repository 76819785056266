var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"hide-actions":_vm.paginationObj.totalItems === 0,"pagination":_vm.paginationObj,"rows-per-page-items":[10],"total-items":_vm.paginationObj.totalItems},on:{"update:pagination":function($event){_vm.paginationObj=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:{ isCanceledLine: props.item.counter_booking_id !== null }},[(_vm.selectBookingsMode)?_c('td',[_c('v-checkbox',{attrs:{"disabled":!!props.item.counter_booking_id ||
            !!props.item.original_booking_id,"primary":"","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1):_vm._e(),_c('td',{class:{ isCanceledText: props.item.counter_booking_id !== null }},[_vm._v(" "+_vm._s(_vm._f("readablePaymentLineType")(props.item.payment_line))+" ("+_vm._s(_vm._f("readableBookingType")(props.item))+") ")]),_c('td',{class:{ isCanceledText: props.item.counter_booking_id !== null }},[_vm._v(" "+_vm._s(props.item.payment_line.payment_data.year)+" ")]),_c('td',{class:{ isCanceledText: props.item.counter_booking_id !== null }},[(_vm.allowedActions.includes('bookings:change'))?_c('a',{on:{"click":function($event){return _vm.$emit('change:booking', props.item)}}},[_vm._v(_vm._s(_vm._f("formatAmountToCurrency")(props.item.amount)))]):_c('span',[_vm._v(_vm._s(_vm._f("formatAmountToCurrency")(props.item.amount)))])]),_c('td',[_vm._v(" "+_vm._s(props.item.master_data.municipality)+" ")]),_c('td',[(props.item.user)?[_vm._v(" "+_vm._s(props.item.user.username)+" ")]:[_vm._v(" System")]],2),_c('td',[(props.item.document)?_c('a',{on:{"click":function($event){return _vm.$emit('openPdf', props.item.document)}}},[_vm._v(" "+_vm._s(_vm._f("extractNameFromPdf")(props.item.document.pdf))+" ")]):_c('span',[_vm._v("Kein Dokument vorhanden")])]),(_vm.isIdoc)?[_c('td',[(props.item.idoc_attachment_status === 'success')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("done_all")])]}}],null,true)},[_vm._v(" IDoc und PDF exportiert ")]):(props.item.idoc_status === 'accepted')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("schedule")])]}}],null,true)},[_vm._v(" IDoc abgeschickt ")]):(['error', 'rejected'].includes(props.item.idoc_status))?_c('TextInfoModal',{attrs:{"comment":props.item.idoc_message,"title":"Fehlermeldung"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("close")])]}}],null,true)}):_vm._e()],1),_c('td',[_vm._v(_vm._s(props.item.idoc_id))])]:_c('td',[(props.item.sap_export_status === 'booked')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("check")])]}}],null,true)},[_vm._v(" Erfolgreich exportiert ")]):(props.item.sap_export_status === 'open')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("schedule")])]}}],null,true)},[_vm._v(" Warte auf Export ")]):(props.item.sap_export_status === 'error')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("close")])]}}],null,true)},[_vm._v(" Fehler beim Export ")]):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item.created_at)+" ")])],2)]}},{key:"pageText",fn:function(props){return [_vm._v(" Einträge "+_vm._s(props.pageStart)+" bis "+_vm._s(props.pageStop)+" von insgesamt "+_vm._s(props.itemsLength)+" ")]}}]),model:{value:(_vm.selectedBookings),callback:function ($$v) {_vm.selectedBookings=$$v},expression:"selectedBookings"}},'v-data-table',{ headers: _vm.headers, items: _vm.items },false))}
var staticRenderFns = []

export { render, staticRenderFns }