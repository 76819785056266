<template>
  <v-card class="mb-4">
    <v-card-title class="pb-0">
      <h3>Stornierung von Buchungen</h3>
      <v-spacer />
    </v-card-title>
    <v-card-text
      ><h4 class="mb-3">
        Es wird eine Stornierung für die folgenden Buchungen vorgenommen
      </h4>

      <v-data-table v-bind="{ headers, items }" hide-actions>
        <template v-slot:items="props">
          <td>{{ props.item.type }}</td>
          <td>{{ props.item.year }}</td>
          <td>{{ props.item.amount }}</td>
          <td>{{ props.item.municipality }}</td>
          <td>{{ props.item.idocId }}</td>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="abort">Abbrechen</v-btn>
      <v-btn color="error" @click="dialogs.confirmCancelation = true"
        >Stornieren</v-btn
      >

      <confirm-modal
        title="Stornierung bestätigen"
        text="Die Stornierung kann nicht rückgängig gemacht werden"
        :ok-action="confirm"
        v-model="dialogs.confirmCancelation"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  readableBookingType,
  readablePaymentLineType,
} from '@/utils/typeToTextHelpers'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

export default {
  name: 'BookingsCancelation',
  components: { ConfirmModal },
  props: {
    bookings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogs: {
        confirmCancelation: false,
      },
    }
  },
  methods: {
    confirm() {
      this.$emit('cancelation:confirm')
    },
    abort() {
      this.$emit('cancelation:abort')
    },
  },
  computed: {
    headers() {
      return [
        { text: 'Typ', sortable: false },
        { text: 'Jahr', sortable: false },
        { text: 'Betrag', sortable: false },
        { text: 'Kommune', sortable: false },
        { text: 'SAP Belegnummer', sortable: false },
      ]
    },
    items() {
      return this.bookings.map((booking) => {
        return {
          type: `${readablePaymentLineType(
            booking.payment_line
          )} (${readableBookingType(booking)})`,
          year: `${booking.payment_line.payment_data.year}`,
          amount: `${formatAmountToCurrency(booking.amount)}`,
          municipality: booking.master_data.municipality,
          idocId: booking.idoc_id,
        }
      })
    },
  },
}
</script>

<style scoped></style>
