<template>
  <v-menu
    bottom
    left
    transition="slide-x-transition"
    v-if="sessionData && allowedActions.length"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-tile v-if="can.cancelBookings" @click="$emit('bookings:cancel')">
        <v-list-tile-title>Buchungen stornieren</v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="can.approve" @click="$emit('session:approve')">
        <v-list-tile-title>Buchungsmappe genehmigen</v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="can.closeSession" @click="$emit('session:close')">
        <v-list-tile-title>Buchungsmappe schließen</v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="can.archiveSession" @click="$emit('session:archive')">
        <v-list-tile-title>Buchungsmappe archivieren</v-list-tile-title>
      </v-list-tile>
      <v-list-tile v-if="can.revertSession" @click="$emit('session:revert')">
        <v-list-tile-title>Genehmigung zurücksetzen</v-list-tile-title>
      </v-list-tile>
      <v-list-tile
        v-if="can.generateReports"
        @click="$emit('session:generate-report')"
      >
        <v-list-tile-title>Reports erneut erzeugen</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import { SessionActions } from '@/utils/enums'

export default {
  name: 'BookingsViewMenu',
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
    allowedActions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    can() {
      return {
        approve: this.allowedActions.includes(SessionActions.approveSession),
        cancelBookings: this.allowedActions.includes(
          SessionActions.stornoBookings
        ),
        generateReports: this.allowedActions.includes(
          SessionActions.generateReports
        ),
        closeSession: this.allowedActions.includes(SessionActions.closeSession),
        archiveSession: this.allowedActions.includes(
          SessionActions.archiveSession
        ),
        revertSession: this.allowedActions.includes(
          SessionActions.revertSession
        ),
      }
    },
  },
}
</script>

<style scoped></style>
