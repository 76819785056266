export function readablePaymentLineType(paymentLine) {
  return {
    secondaryPrePayments: 'Korrekturzahlung',
    additionalPayments: 'Nachzahlung',
    finalPayments: 'Abschlusszahlung',
    taxAuditPayments: 'Betriebsprüfung',
    prePayments: 'Vorausz.',
  }[paymentLine.type]
}

export function readableBookingType(booking) {
  return {
    tax: 'GewSt.',
    interest: 'Zins',
    interest_refund: 'Erst.Zins',
    interest_additional: 'Nachz.Zins',
  }[booking.type]
}
