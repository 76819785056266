<template>
  <v-card>
    <v-card-title class="pb-0">
      <h3>Historie</h3>
      <v-spacer />
    </v-card-title>
    <template v-if="logs.length">
      <v-data-table v-bind="{ headers, items: logs }" hide-actions>
        <template #items="props">
          <tr>
            <td>
              {{ props.item.type | readableType }}
            </td>
            <td>
              <template v-if="props.item.user">
                {{ props.item.user.username }}
              </template>
              <template v-else> System</template>
            </td>
            <td>
              <TextInfoModal
                v-if="props.item.comment"
                :comment="props.item.comment"
              />
              <template v-else>keiner</template>
            </td>

            <td class="text-xs-right">
              {{ props.item.created_at | formatDate }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>

<script>
import formatDate from 'date-fns/format'
import TextInfoModal from '@/components/modals/TextinfoModal.vue'

export default {
  name: 'BookingsLog',
  components: { TextInfoModal },
  computed: {
    headers() {
      return [
        { text: 'Neuer Zustand', sortable: false },
        { text: 'Benutzer', sortable: false },
        { text: 'Kommentar', sortable: false },
        { text: 'Datum', align: 'right', sortable: false },
      ]
    },
    logs() {
      return this.$store.state.sessionLogs
    },
  },
  filters: {
    readableType(type) {
      return {
        open_session: 'BM erstellt',
        closed_session: 'BM geschlossen',
        approved_session: 'BM genehmigt',
        update_booking: 'Buchungswert geändert',
      }[type]
    },
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY (HH:mm [Uhr])')
    },
  },
}
</script>

<style scoped></style>
