<template>
  <app-layout>
    <template v-if="sessionData">
      <v-layout>
        <v-flex>
          <v-card class="mb-4">
            <v-card-title class="pb-0">
              <h3>
                {{ sessionData.name }}
                <v-chip
                  v-if="sessionData.state === 'approved'"
                  dark
                  color="success"
                  >Genehmigt</v-chip
                >
                <v-chip
                  v-else-if="sessionData.state === 'closed'"
                  dark
                  color="warning"
                  >Geschlossen</v-chip
                >
                <v-chip
                  v-else-if="sessionData.state === 'archived'"
                  dark
                  color="error"
                  >Archiviert</v-chip
                >
                <v-chip v-else-if="sessionData.active">Offen</v-chip>
              </h3>
              <v-spacer />
              <template v-if="!sessionData.active">
                <BookingsDownloadReports :session="sessionData" />
              </template>
              <bookings-view-menu
                :session-data="sessionData"
                :allowed-actions="allowedActions"
                @session:close="closeBookingSession"
                @session:approve="dialogs.approve = true"
                @session:archive="dialogs.archive = true"
                @session:revert="dialogs.revert = true"
                @session:generate-report="generateReports"
                @bookings:cancel="selectBookingsMode = true"
              />
            </v-card-title>
            <template v-if="items">
              <v-card-text v-if="sessionData.has_failed_jobs">
                <v-alert :value="true" type="error" class="mb-4 flex">
                  <div class="d-flex">
                    <div>
                      <div class="title">Fehler bei der Übermittlung</div>
                      <span
                        >In dieser Buchungsmappe gibt es fehlerhafte IDoc
                        Buchungen</span
                      >
                    </div>
                    <v-spacer />
                    <v-btn @click="triggerIdocExport"
                      >Fehlg. IDocs erneut probieren</v-btn
                    >
                  </div>
                </v-alert>
              </v-card-text>

              <BookingsStatus :session-data="sessionData" />

              <BookingsTable
                :pagination="pagination"
                :items="items"
                :select-bookings-mode="selectBookingsMode"
                :allowed-actions="allowedActions"
                @openPdf="(document) => (extractionData = document)"
                @update:pagination="(data) => (pagination = data)"
                @change:booking="openChangeBookingDialog"
                @select:booking="(data) => (selectedBookings = data)"
              />
            </template>
          </v-card>

          <BookingsCancelation
            v-if="selectedBookings.length"
            :bookings="selectedBookings"
            @cancelation:abort="selectBookingsMode = false"
            @cancelation:confirm="cancelSelectedBookings"
          />
          <BookingsLog />
        </v-flex>
      </v-layout>

      <modal-pdf-fullscreen v-model="extractionData" />

      <confirm-modal
        :ok-action="approveSession"
        text="Wollen Sie diese Buchungsmappe genehmigen? Dadurch wird die Buchungsmappe abgschlossen und ins SAP exportiert. "
        title="Buchungsmappe genehmigen"
        v-model="dialogs.approve"
      >
        <v-card-text>
          <datepicker label="Buchungsdatum" v-model="approvedAt" />
        </v-card-text>
      </confirm-modal>

      <confirm-modal
        v-model="dialogs.archive"
        title="Buchungsmappe archivieren?"
        text="Anschließend kann die Mappe nicht mehr genehmigt und exportiert werden. "
        :ok-action="archiveSession"
      >
      </confirm-modal>

      <confirm-modal
        v-model="dialogs.revert"
        title="Buchungsmappe zurückrollen?"
        text="Die Buchungsmappe wird auf den Stand vor der Genehmigung zurückgesetzt und muss dann nochmals genehmigt werden."
        :ok-action="revertSession"
      >
      </confirm-modal>

      <confirm-modal
        v-model="dialogs.bookingChange"
        title="Buchung ändern"
        text="Neuer Betrag?"
        :ok-action="saveChangeBooking"
      >
        <v-card-text v-if="changedBooking">
          <simple-money-input
            v-model="changedBookingModel"
            label="Neuer Betrag"
          />
        </v-card-text>
      </confirm-modal>
    </template>
  </app-layout>
</template>

<script>
import bookingsApi from '@/api/bookingsApi'
import AppLayout from '@/components/AppLayout'
import formatDate from 'date-fns/format'
import ModalPdfFullscreen from '@/components/pdf/ModalPDFFullscreen'
import BookingsViewMenu from '@/pages/bookings/BookingsViewMenu'
import ConfirmModal from '@/components/modals/ConfirmModal'
import BookingsDownloadReports from '@/pages/bookings/BookingsDownloadReports.vue'
import { mapActions } from 'vuex'
import BookingsLog from '@/pages/bookings/BookingsLog.vue'
import Datepicker from '@/components/dates/Datepicker.vue'
import BookingsStatus from '@/pages/bookings/BookingsStatus.vue'
import BookingsTable from '@/pages/bookings/BookingsTable.vue'
import SimpleMoneyInput from '@/pages/zerlegungView/SimpleMoneyInput.vue'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import BookingsCancelation from '@/pages/bookings/BookingsCancelation.vue'
import { SessionActions } from '@/utils/enums'

export default {
  name: 'Bookings',
  components: {
    BookingsCancelation,
    SimpleMoneyInput,
    BookingsTable,
    BookingsStatus,
    Datepicker,
    BookingsLog,
    BookingsDownloadReports,
    ConfirmModal,
    BookingsViewMenu,
    ModalPdfFullscreen,
    AppLayout,
  },
  props: ['id'],
  data() {
    return {
      loading: false,
      loadingBookingSession: false,
      loadingRMSession: false,
      rowsPerPageItems: [20],
      intervalIds: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
      currentIndex: 1,
      sessionData: null,
      extractionData: null,
      dialogs: {
        approve: false,
        bookingChange: false,
        archive: false,
        revert: false,
      },
      approvedAt: formatDate(new Date(), 'YYYY-MM-DD'),
      changedBooking: null,
      changedBookingModel: null,
      selectBookingsMode: false,
      selectedBookings: [],
    }
  },
  computed: {
    items() {
      if (this.sessionData) {
        return this.sessionData.bookings.data
      }
      return null
    },
    allowedActions() {
      const result = []
      const state = this.sessionData.state
      const isStornoSession = this.sessionData.name.startsWith(
        'Korrekturbuchungsmappe'
      )
      if (state === 'open') {
        result.push(SessionActions.changeBooking, SessionActions.closeSession)
      } else {
        result.push(SessionActions.generateReports)
      }

      if (state === 'closed') {
        if (!isStornoSession) {
          result.push(SessionActions.changeBooking)
        }
        result.push(SessionActions.approveSession)
        result.push(SessionActions.archiveSession)
      }

      if (state === 'approved') {
        if (!isStornoSession) {
          result.push(SessionActions.stornoBookings)
        }
        if (this.sessionData.is_revertable) {
          result.push(SessionActions.revertSession)
        }
      }
      return result
    },
  },
  methods: {
    ...mapActions(['updateBookingSessionLogs']),
    async closeBookingSession() {
      // close the session (this request will be very long running. So dont try to await it
      await bookingsApi.closeSession(this.id)
      await this.updateBookingSessionLogs(this.id)
    },

    generateReports() {
      // empty the vm queues and set them to empty, so that they dont interfere with old queues
      bookingsApi.generateReports(this.id)
    },
    async loadPage(page) {
      if (!this.loading) {
        try {
          this.loading = true
          this.sessionData = await bookingsApi.getEntry(this.id, page)
          await this.$store.dispatch(
            'setCurrentCompany',
            this.sessionData.company_id
          )
          this.pagination.totalItems = this.sessionData.bookings.total
          this.pagination.page = this.sessionData.bookings.current_page
          this.pagination.rowsPerPage = this.sessionData.bookings.per_page
        } catch (error) {
          if (error.message === 'user is not authenticated') {
            return this.$router.replace('/login')
          }
        } finally {
          this.loading = false
        }
      }
    },
    async approveSession() {
      this.loading = true
      await bookingsApi.approveSession(this.id, this.approvedAt)
      await this.loadPage(0)
      await this.updateBookingSessionLogs(this.id)
      this.loading = false
    },

    updateData() {
      this.updateDataIntervalId = setInterval(() => {
        this.loadPage(this.pagination.page)
      }, 2000)
    },
    stopUpdateData() {
      clearInterval(this.updateDataIntervalId)
    },

    openChangeBookingDialog(bookingEntry) {
      this.changedBooking = bookingEntry
      this.changedBookingModel = bookingEntry.amount
      this.dialogs.bookingChange = true
    },

    async archiveSession() {
      await bookingsApi.archiveSession(this.sessionData.id)
      this.$root.setSnackbar('success', 'Buchungsmappe archiviert')
      await this.updateBookingSessionLogs(this.id)
    },

    async revertSession() {
      await bookingsApi.revertSessionToClosed(this.sessionData.id)
      this.$root.setSnackbar('success', 'Buchungsmappe zurückgesetzt')
      await this.updateBookingSessionLogs(this.id)
    },

    async saveChangeBooking() {
      const amount = parseInt(this.changedBookingModel)
      if (Number.isNaN(amount) || amount === 0) {
        return
      }

      const changedEntry = { id: this.changedBooking.id, amount }
      await bookingsApi.correctBooking(this.sessionData.id, [changedEntry])
      await this.updateBookingSessionLogs(this.id)
      this.$root.setSnackbar('success', 'Buchung aktualisiert')
    },

    async cancelSelectedBookings() {
      const storno = this.selectedBookings.map((el) => el.id)
      await bookingsApi.cancelBookings(this.sessionData.id, storno)
      await this.updateBookingSessionLogs(this.id)
      this.$root.setSnackbar('success', 'Buchungen storniert')
      this.selectBookingsMode = false
    },

    async triggerIdocExport() {
      await bookingsApi.retriggerIdocExport(this.sessionData.id)
      this.$root.setSnackbar('success', 'Übermittlung gestartet')
    },
  },
  created() {
    this.updateBookingSessionLogs(this.id)
    this.updateData()
  },
  beforeDestroy() {
    this.stopUpdateData()
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
  },
  filters: {
    formatAmountToCurrency,
  },
}
</script>

<style scoped></style>
